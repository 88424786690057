<template>
  <div>
    <!-- <b-breadcrumb
      class="breadcrumb pb-2 pt-1 noprint"
      :items="breadcrumb_items"
    ></b-breadcrumb> -->
    <div style="display: flex">
      <div class="col" style="text-align: left">
        <button
          v-can="'mesh.add_campus'"
          v-if="allows_crud"
          class="btn btn-secondary btn-sm"
          v-b-modal.new-campus-modal
        >
          Agregar {{ $getVisibleNames("mesh.campus", false, "Sede") }}
        </button>
      </div>
    </div>
    <h3 class="mt-3">
      {{ $getVisibleNames("mesh.campus", true, "Sedes") }}
      <AlternativeNameButton
        :table_name="'mesh.campus'"
        :allows_crud="allows_crud"
      ></AlternativeNameButton>
    </h3>
    <GenericBTable
      v-if="campusList"
      :items="campusList"
      :fields="fields_campuses"
      :pagination="campusList.length"
      :filterCustom="filterCustom"
      @emitChangeSearchField="changeInputSearch"
      primary-key="id"
      :filter="input_search"
      :show_pagination="true"
      :search_filter="true"
      :columns_display="true"
      :allows_crud="allows_crud"
      :display_id="'campus'"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:cell(school)="row">
        {{ getInstitutionName(row.item.school) }}
      </template>
      <template v-slot:cell(address_line)="row">
        {{ getAddressName(row.item.address) }}
      </template>
      <template v-if="allows_crud" v-slot:cell(actions)="row">
        <b-modal
          :id="`edit-campus-modal-${row.item.id}`"
          :title="`Editar ${$getVisibleNames('mesh.campus', false, 'Sede')}`"
          size="lg"
          hide-footer
        >
          <CampusForm
            :Campus="row.item"
            @updated="slotUpdatedCampus"
            @created_address="slotCreatedAddress"
            @updated_address="slotUpdatedAddress"
          ></CampusForm>
        </b-modal>
        <button-edit
          v-can="'mesh.change_campus'"
          @click="$bvModal.show(`edit-campus-modal-${row.item.id}`)"
          v-b-tooltip.v-secondary.auto.noninteractive="{
            customClass: 'custom-tooltip',
          }"
          :title="`Editar ${$getVisibleNames('mesh.campus', false, 'Sede')}`"
        ></button-edit>
        <button-delete
          v-can="'mesh.delete_campus'"
          @click="askForDeleteCampus(row.item.id)"
          v-b-tooltip.v-secondary.auto.noninteractive="{
            customClass: 'custom-tooltip',
          }"
          :title="`Eliminar ${$getVisibleNames('mesh.campus', false, 'Sede')}`"
        ></button-delete>
      </template>
    </GenericBTable>
    <div>
      <b-modal
        id="new-campus-modal"
        :title="`Crear ${$getVisibleNames('mesh.campus', false, 'Sede')}`"
        size="lg"
        hide-footer
      >
        <CampusForm
          @created_address="slotCreatedAddress"
          @created="slotCreatedCampus"
        ></CampusForm>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "CampusListView",
  props: {
    display_id: {},
  },
  components: {
    CampusForm: () => import("./CampusForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
  },
  data() {
    return {
      input_search: "",
      sortBy: "",
      sortDesc: false,
      address: [],
    };
  },
  computed: {
    ...mapGetters({
      communes: names.COMMUNES,
      campuses: names.CAMPUSES,
      school: "getInstitution",
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_campus");
      if (has_permission) return has_permission;
      else return false;
    },
    // breadcrumb_items() {
    //   return [
    //     { text: "Definiciones Organizacionales", active: true },
    //     {
    //       text: this.$getVisibleNames("mesh.campus", true, "Sedes"),
    //       active: true,
    //     },
    //   ];
    // },
    campusList() {
      if (this.school)
        return this.campuses.filter((x) => x.school == this.school.id);
      else console.log("Institución no encontrada!!!");
      return this.campuses;
    },
    fields_campuses() {
      return [
        {
          key: "name",
          label: String(this.$getVisibleNames("mesh.campus", true, "Sedes")),
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
        },
        {
          key: "address",
          label: this.$getVisibleNames("common.commune", false, "Comuna"),
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          formatter: (value) => this.getCommuneName(value),
        },
        {
          key: "address_line",
          label: "Dirección",
          sortable: false,
          display_column: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "phone",
          label: "Teléfono",
          sortable: true,
          display_column: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(row.phone, this.input_search) ||
        this.$filtered(this.getAddressName(row.address), this.input_search) ||
        this.$filtered(this.getCommuneName(row.address), this.input_search)
      ) {
        return true;
      } else return false;
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },
    getInstitutionName(institution_id) {
      if (this.school && institution_id == this.school.id)
        return this.school.name;
      return "-";
    },
    getCommuneName(address_id) {
      if (!address_id) return "";
      else {
        const address = this.address.find((x) => x.id == address_id);
        if (address) {
          const commune = this.communes.find((x) => x.id == address.commune);
          return commune ? commune.name : "";
        } else return "";
      }
    },
    getAddressName(address_id) {
      const address = this.address.find((x) => x.id == address_id);
      return address ? address.line_1 : "";
    },
    slotCreatedAddress(response) {
      this.address.push(response);
    },
    slotUpdatedAddress(response) {
      const index = this.address.findIndex((x) => x.id == response.id);
      if (index != -1) {
        this.address[index].commune = response.commune;
        this.address[index].zip_code = response.zip_code;
        this.address[index].line_1 = response.line_1;
        this.address[index].line_2 = response.line_2;
      }
    },
    slotCreatedCampus() {
      this.$bvModal.hide("new-campus-modal");
    },
    slotUpdatedCampus(campus) {
      this.$bvModal.hide(`edit-campus-modal-${campus.id}`);
    },
    askForDeleteCampus(campus_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.campus",
          false,
          "Sede"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CAMPUS, campus_id).then(() => {
            toast(
              String(
                this.$getVisibleNames("mesh.campus", false, "Sede") +
                  " eliminada."
              )
            );
          });
        }
      });
    },
  },
  created() {
    this.sortBy = "name";
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_REGIONS);
    this.$store.dispatch(names.FETCH_COUNTRIES);
    this.$store.dispatch(names.FETCH_COMMUNES);
    this.$restful.Get(`/common/address/`).then((response) => {
      this.address = response;
    });
  },
};
</script>

<style scoped>
.custom-td {
  width: 45% !important;
}
.campus-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.add-campus {
  margin-left: 70% !important;
  margin-bottom: -8%;
  padding-top: 1.3%;
}
.custon-tooltip {
  margin-top: 10px;
  z-index: 1;
}
.custon-tooltip::after {
  margin-right: 0.2em;
  transition: 0.2s ease-out;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>